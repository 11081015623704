import debounce from 'utils/debounce.js';

import { gsap } from 'gsap';

class Marquee extends HTMLElement {
  constructor() {
    super();

    this.wrapper = this.querySelector('.wrapper');
    this.firstElement = this.querySelector('.item');
    this.isPaused = false;
  }

  connectedCallback() {
    document.fonts.ready.then(() => {
      this.addClonedElements();
      this.prepareAnimation();
      this.playAnimation();
    });

    this.addEventListener('mouseover', this.pauseAnimation );
    this.addEventListener('mouseout', this.playAnimation );

    this.resizer = debounce(300, this.addClonedElements.bind(this));
    window.addEventListener('resize', this.resizer, { capture: true, passive: true });
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.resizer, { capture: true, passive: true } );
    this.removeEventListener('mouseover', this.pauseAnimation );
    this.removeEventListener('mouseout', this.playAnimation );
    this.marquee.kill();
  }

  addClonedElements() {
    const currentEls = this.children.length
    const elsNeeded = Math.ceil(this.clientWidth / this.firstElement.clientWidth) + 1;

    if(this.firstElement && currentEls < elsNeeded) {
      for (var c = currentEls; c < elsNeeded; c++) {
        let newEl = this.firstElement;
        newEl.removeAttribute('style');
        this.wrapper.insertAdjacentHTML('beforeend', newEl.outerHTML);
      }
    }
  }

  prepareAnimation() {
    let itemWidth = this.firstElement.clientWidth;

    this.marquee = gsap.timeline({
      paused: true
    });

    this.marquee.from(this, {
      opacity: 0,
      duration: .25,
      ease: "power3.Out"
    }, .25);

    this.marquee.from(this.wrapper.children, {
      opacity: 0,
      y: 40,
      duration: .5,
      ease: "power3.Out",
      stagger: 0.15 
    }, .5);

    this.marquee.to(this.wrapper, {
      x: this.wrapper.dataset.direction == "left" ? -itemWidth : itemWidth, 
      duration: (itemWidth / this.clientWidth) * 10,
      repeat: -1,
      ease: "none"
    }, .5);
  }

  playAnimation() {
    this.marquee.play();
  }

  pauseAnimation() {
    this.marquee.pause();
  }
}
customElements.define('ticker-marquee', Marquee);